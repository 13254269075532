<template>
  <b-modal ref="myQuestion" id="modal-2" title="Reject Reason" hide-footer size="lg" centered>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              {{ reason }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <b-btn  class="btn btn-primary close" @click="hideQuestionModal">Cancel</b-btn>
            </div>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    reason: String,
  },
  data() {
    return {
      showModel: false,
    };
  },
  methods:{
    hideQuestionModal(){
      this.$refs.myQuestion.hide()
    }
  }
};
</script>